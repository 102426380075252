exports.components = {
  "component---src-pages-landing-slug-js": () => import("./../../../src/pages/{Landing.slug}.js" /* webpackChunkName: "component---src-pages-landing-slug-js" */),
  "component---src-pages-post-slug-js": () => import("./../../../src/pages/{Post.slug}.js" /* webpackChunkName: "component---src-pages-post-slug-js" */),
  "component---src-pages-posts-js": () => import("./../../../src/pages/posts.js" /* webpackChunkName: "component---src-pages-posts-js" */),
  "component---src-pages-search-term-js": () => import("./../../../src/pages/search/[...term].js" /* webpackChunkName: "component---src-pages-search-term-js" */),
  "component---src-pages-speaker-index-js": () => import("./../../../src/pages/speaker/index.js" /* webpackChunkName: "component---src-pages-speaker-index-js" */),
  "component---src-pages-speaker-talk-slug-js": () => import("./../../../src/pages/speaker/{Talk.slug}.js" /* webpackChunkName: "component---src-pages-speaker-talk-slug-js" */),
  "component---src-pages-subs-index-js": () => import("./../../../src/pages/subs/index.js" /* webpackChunkName: "component---src-pages-subs-index-js" */),
  "component---src-pages-subs-preferences-js": () => import("./../../../src/pages/subs/preferences.js" /* webpackChunkName: "component---src-pages-subs-preferences-js" */),
  "component---src-pages-subs-reminders-js": () => import("./../../../src/pages/subs/reminders.js" /* webpackChunkName: "component---src-pages-subs-reminders-js" */),
  "component---src-pages-subs-welcome-js": () => import("./../../../src/pages/subs/welcome.js" /* webpackChunkName: "component---src-pages-subs-welcome-js" */),
  "component---src-templates-posts-tag-archive-js": () => import("./../../../src/templates/posts-tag-archive.js" /* webpackChunkName: "component---src-templates-posts-tag-archive-js" */)
}

